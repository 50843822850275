import { PortalModule } from '@/modules';
import { routes } from './routes';
import { financeManagementStore } from './store';

export const financeManagementModule: PortalModule = {
  routes,
  store: {
    name: 'financeManagement',
    module: financeManagementStore,
  }
}