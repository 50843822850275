import { authHeaders } from '@/api';
import { apiUrl } from '@/env';
import axios from 'axios';
import { IBudgetCategoryCreate, IBudgetCreate, IExpenseCreate } from './types';


export const api = {
  async getBudgetCategories(token: string) {
    return axios.get(`${apiUrl}/api/v1/finance/categories/tree`, authHeaders(token));
  },
  async createBudgetCategory(token: string, payload: IBudgetCategoryCreate) {
    return axios.post(`${apiUrl}/api/v1/finance/categories/`, payload, authHeaders(token));
  },
  async updateBudgetCategory(token: string, budgetCategoryId: number, payload: IBudgetCategoryCreate) {
    return axios.put(`${apiUrl}/api/v1/finance/categories/${budgetCategoryId}`, payload, authHeaders(token));
  },
  async createBudget(token: string, payload: IBudgetCreate) {
    return axios.post(`${apiUrl}/api/v1/finance/budgets/`, payload, authHeaders(token));
  },
  async updateBudget(token: string, budgetId: number, payload: IBudgetCreate) {
    return axios.put(`${apiUrl}/api/v1/finance/budgets/${budgetId}`, payload, authHeaders(token));
  },
  async getExpensesForBudget(token: string, budgetId: number) {
    return axios.get(`${apiUrl}/api/v1/finance/budgets/expenses/${budgetId}`, authHeaders(token));
  },
  async getMyExpenses(token: string) {
    return axios.get(`${apiUrl}/api/v1/finance/expenses/me`, authHeaders(token));
  },
  async deactivateBudgetCategory(token: string, budgetCategoryId: number) {
    return axios.delete(`${apiUrl}/api/v1/finance/categories/${budgetCategoryId}/deactivate`, authHeaders(token));
  },
  async deactivateBudget(token: string,  budgetId: number) {
    return axios.delete(`${apiUrl}/api/v1/finance/budgets/${budgetId}/deactivate`, authHeaders(token));
  },
  async createExpense(token: string, payload: IExpenseCreate) {
    return axios.post(`${apiUrl}/api/v1/finance/expenses/`, payload, authHeaders(token));
  },
  async updateExpense(token: string, expenseId: number, payload: IExpenseCreate) {
    return axios.put(`${apiUrl}/api/v1/finance/expenses/${expenseId}`, payload, authHeaders(token));
  },
  async getExpense(token: string, expenseId: number) {
    return axios.get(`${apiUrl}/api/v1/finance/expenses/${expenseId}`, authHeaders(token));
  }
};