import { mutations } from './mutations';
import { getters, readBudgetsInCategory } from './getters';
import { actions } from './actions';
import { FinanceManagementState } from './state';

const defaultState: FinanceManagementState = {
  budgetCategoryTree: [],
  expenses: [],
};

export const financeManagementStore = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
