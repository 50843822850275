import { RouteConfig } from 'vue-router';
import RouterComponent from '@/components/RouterComponent.vue';

export const routes: RouteConfig[] = [
  {
    path: 'finance-management',
    component: RouterComponent,
    children: [
      {
        path: '',
        redirect: 'budget-overview',
      },
      {
        path: 'budget-overview',
        name: 'budget-overview',
        component: () => import(/* webpackChunkName: "budget" */ './views/BudgetListView.vue'),
      },
      {
        path: 'budget-details/:id',
        name: 'budget-details',
        component: () => import(/* webpackChunkName: "budget" */ './views/BudgetDetailView.vue'),
      },
      {
        path: 'budget-administration',
        component: () => import(/* webpackChunkName: "budget" */ './views/BudgetAdministrationView.vue'),
      },
      {
        path: 'expenses',
        component: RouterComponent,
        children: [
          {
            path: '',
            redirect: 'me',
          },
          {
            path: 'me',
            component: () => import(/* webpackChunkName: "expenses" */ './views/expenses/ExpenseListView.vue'),
          },
          {
            path: 'create',
            name: 'expense-create',
            component: () => import(/* webpackChunkName: "expenses" */ './views/expenses/ExpenseEdit.vue'),
          },
          {
            path: 'edit/:id',
            name: 'expense-edit',
            component: () => import(/* webpackChunkName: "expenses" */ './views/expenses/ExpenseEdit.vue'),
          },
          {
            path: ':id',
            name: 'expense-details',
            component: () => import(/* webpackChunkName: "expenses" */ './views/expenses/ExpenseDetailView.vue'),
          },
        ]
      }

    ]
  }
]