import { apiCall, apiCallNotify } from '@/store/utils';
import { State } from '@/store/state';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { FinanceManagementState } from './state';
import { api } from '../api';
import { commitSetBudgetCategoryTree, commitSetExpenses } from './mutations';
import { IBudgetCategoryCreate, IBudgetCreate, IExpenseCreate } from '../types';

type MainContext = ActionContext<FinanceManagementState, State>;

export const actions = {
  async actionGetBudgetCategoryTree(context: MainContext) {
    const response = await apiCall(context, api.getBudgetCategories);
    commitSetBudgetCategoryTree(context, response.data);
    return response.data;
  },
  async actionGetExpensesForBudget(context: MainContext, budgetId: number) {
    const response = await apiCall(context, (token) => api.getExpensesForBudget(token, budgetId));
    commitSetExpenses(context, response.data);
    return response.data;
  },
  async actionGetMyExpenses(context: MainContext) {
    const response = await apiCall(context, api.getMyExpenses);
    commitSetExpenses(context, response.data);
    return response.data;
  },
  async actionUpdateExpense(context: MainContext, payload: { id: number; payload: IExpenseCreate }) {
    const response = await apiCallNotify(context, (token) => api.updateExpense(token, payload.id, payload.payload));
    commitSetExpenses(context, [response.data]);
    return response.data;
  },
  async actionCreateExpense(context: MainContext, payload: IExpenseCreate) {
    const response = await apiCallNotify(context, (token) => api.createExpense(token, payload));
    commitSetExpenses(context, [response.data]);
    return response.data;
  },
  async actionGetExpense(context: MainContext, id: number) {
    const response = await apiCall(context, (token) => api.getExpense(token, id));
    commitSetExpenses(context, [response.data]);
    return response.data;
  },
  async actionCreateBudgetCategory(context: MainContext, payload: IBudgetCategoryCreate) {
    const response = await apiCallNotify(context, (token) => api.createBudgetCategory(token, payload));
    // reload budget category tree
    await dispatchGetBudgetCategoryTree(context);
    return response.data;
  },
  async actionUpdateBudgetCategory(context: MainContext, payload: { id: number; payload: IBudgetCategoryCreate }) {
    const response = await apiCallNotify(context, (token) => api.updateBudgetCategory(token, payload.id, payload.payload));
    // reload budget category tree
    await dispatchGetBudgetCategoryTree(context);
    return response.data;
  },
  async actionCreateBudget(context: MainContext, payload: IBudgetCreate) {
    const response = await apiCallNotify(context, (token) => api.createBudget(token, payload));
    await dispatchGetBudgetCategoryTree(context);
    return response.data;
  },
  async actionUpdateBudget(context: MainContext, payload: { id: number; payload: IBudgetCreate }) {
    const response = await apiCallNotify(context, (token) => api.updateBudget(token, payload.id, payload.payload));
    await dispatchGetBudgetCategoryTree(context);
    return response.data;
  },
  async actionDeactivateBudget(context: MainContext, id: number){
    const responce = await apiCallNotify(context,(token) => api.deactivateBudget(token,id));
    await dispatchGetBudgetCategoryTree(context);
  },
  async actionDeactivateBudgetCategory(context: MainContext, id: number){
    const responce = await apiCallNotify(context,(token) => api.deactivateBudgetCategory(token,id));
    await dispatchGetBudgetCategoryTree(context);
  }
};

const { dispatch } = getStoreAccessors<FinanceManagementState | any, State>('');

export const dispatchGetBudgetCategoryTree = dispatch(actions.actionGetBudgetCategoryTree);
export const dispatchGetExpensesForBudget = dispatch(actions.actionGetExpensesForBudget);
export const dispatchGetMyExpenses = dispatch(actions.actionGetMyExpenses);
export const dispatchCreateBudgetCategory = dispatch(actions.actionCreateBudgetCategory);
export const dispatchUpdateBudgetCategory = dispatch(actions.actionUpdateBudgetCategory);
export const dispatchCreateBudget = dispatch(actions.actionCreateBudget);
export const dispatchUpdateBudget = dispatch(actions.actionUpdateBudget);
export const dispatchDeactivateBudget = dispatch(actions.actionDeactivateBudget);
export const dispatchDeactivateBudgetCategory = dispatch(actions.actionDeactivateBudgetCategory);
export const dispatchUpdateExpense = dispatch(actions.actionUpdateExpense);
export const dispatchCreateExpense = dispatch(actions.actionCreateExpense);
export const dispatchGetExpense = dispatch(actions.actionGetExpense);

