import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '@/store/state';
import { FinanceManagementState } from './state';
import { IBudget, IBudgetCategory, IExpense } from '../types';
import { Route } from 'vue-router';

function convertToList(node: IBudgetCategory) {
  const list = [node]

  node.children.forEach(child => {
    list.push(...convertToList(child))
  })

  return list
}

export const getters = {
  expenseList: (state: FinanceManagementState) => {
    return state.expenses;
  },
  budgetList: (state: FinanceManagementState) => {
    const categories = getters.budgetCategoryList(state)

    const list:IBudget[] = []
    categories.forEach(category => {
      list.push(...category.budgets)
    })
    return list
  },
  budgetCategoryList: (state: FinanceManagementState) => {
    const categories = getters.budgetCategoryTree(state)

    const list:IBudgetCategory[] = []
    categories?.forEach(category => {
      list.push(...convertToList(category))
    })
    return list
  },
  budgetCategoryTree: (state: FinanceManagementState) => state.budgetCategoryTree,

  categoriesInCategory: (state: FinanceManagementState) => (category: IBudgetCategory) => {
    return convertToList(category)
  },

  budgetsInCategory: (state: FinanceManagementState) => (category: IBudgetCategory) => {
    const categories = getters.categoriesInCategory(state)(category)
    
    const list: IBudget[] = []
    categories.forEach(category => {
      list.push(...category.budgets)
    })
    return list
  },
  oneBudget: (state: FinanceManagementState) => (id: number) => {
    // TODO: this is not efficient, but it's not a big deal for now
    const budgets = getters.budgetList(state)
    return budgets.find(budget => budget.id === id)
  },
  expensesInBudget: (state: FinanceManagementState) => (id: number) => {
    return state.expenses?.filter(expense => expense.budget_id === id);
  },
  myExpenses: (state: FinanceManagementState) => (userId: number) => {
    return state.expenses?.filter(expense => expense.author_id === userId);
  },
  oneExpense: (state: FinanceManagementState) => (id: number) => {
    return state.expenses?.find(expense => expense.id === id);
  },
  routeExpense: (state: FinanceManagementState) => (route: Route) => {
    const id = Number(route.params.id)
    return getters.oneExpense(state)(id)
  }
};

const {read} = getStoreAccessors<FinanceManagementState, State>('');

export const readExpenseList = read(getters.expenseList);
export const readBudgetList = read(getters.budgetList);
export const readOneBudget = read(getters.oneBudget);
export const readExpensesInBudget = read(getters.expensesInBudget);
export const readMyExpenses = read(getters.myExpenses);
export const readBudgetCategoryList = read(getters.budgetCategoryList);
export const readBudgetCategoryTree = read(getters.budgetCategoryTree);
export const readCategoriesInCategory = read(getters.categoriesInCategory);
export const readBudgetsInCategory = read(getters.budgetsInCategory);
export const readOneExpense = read(getters.oneExpense);
export const readRouteExpense = read(getters.routeExpense);