import { FinanceManagementState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '@/store/state';
import { replace, replaceAll } from '@/store/utils';
import { IExpense } from '../types';


export const mutations = {
  setBudgetCategoryTree(state: FinanceManagementState, payload: any) {
    state.budgetCategoryTree = payload;
  },
  setExpenses(state: FinanceManagementState, payload: IExpense[]) {
    state.expenses = replaceAll(state.expenses, payload);
  },
};

const {commit} = getStoreAccessors<FinanceManagementState | any, State>('');

export const commitSetBudgetCategoryTree = commit(mutations.setBudgetCategoryTree);
export const commitSetExpenses = commit(mutations.setExpenses);
